/**
 * Common utils
 */

/**
 * https://stackoverflow.com/a/43467144
 * @param string The string to check
 * @returns boolean
 */
export const isValidHttpUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 * Returns true when running on a client
 */
export const isBrowser = typeof window !== 'undefined' && !!window;
